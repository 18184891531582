<template>
	<v-card>
		<v-skeleton-loader type="card, list-item-two-line" v-if="loading" ></v-skeleton-loader>
		<v-row class="pa-5">
			<v-col class="col-9 col-sm-4">{{ post.date_of_publication }}</v-col>
			<v-spacer></v-spacer>
			<v-col class="col-1">:</v-col>
			<v-col class="col-12 text-h4">{{ post.title }}</v-col>
			<v-col class="col-12"><hr></v-col>
			<v-col class="col-12"><v-img src="" alt=""></v-img></v-col>
			<v-col class="col-12"><span v-html="post.content"></span><div v-html="script"></div></v-col>
			<v-col class="col-12 d-none"><hr></v-col>
			<v-col class="col-9 col-sm-4 d-none">Просмотров: {{ post.views }}</v-col>
			<v-spacer></v-spacer>
			<v-col class="col-3 col-sm-1 d-none"><v-icon large>mdi-heart-outline</v-icon></v-col>
		</v-row>
	</v-card>
</template>

<script type="text/javascript">

export default {
	name: 'Post',
	props: [ 'post_id',],
	data () {return {
		script:'',
		get_script: false,
	} },
	methods: {
		async loadVk(script){
			//console.log("Load VK")
			// Загружаем скрипт VK
			//var s = document.createElement('script')
			//s.src = 'https://vk.com/js/api/openapi.js?169'
			//var r = await function() { document.getElementsByTagName("head")[0].appendChild(s) }
			//console.log("R:", r)
			var s2 = document.createElement('script')
			console.log("script:", script)
			//s2.innerHTML = "(function() {VK.Widgets.Post(\"vk_post_-146570845_2612\", -146570845, 2612, 'tPTACUmr6SMjyanpf865bmRL');}());"
			//s2.innerHTML = "(function() {" + script + ";}());"
			s2.innerHTML = script 
			console.log(s2)
			document.getElementsByTagName("head")[0].appendChild(s2)
		}
	},
	computed: {
		post() { return this.$store.getters.getPost },
		script2() { 
			// Скрипт VK подгружается в самом начале
			var res = this.$store.getters.getPost
			if (!res.script) { return '' }
			//<div id="vk_post_-146570845_2612"></div>
			///VK.Widgets.Post("vk_post_-146570845_2612", -146570845, 2612, 'tPTACUmr6SMjyanpf865bmRL');
			//console.log('s:', res.pure_html)
			var script = res.pure_html.match(/(VK.Widgets.Post\("vk_post_-\d+_\d+", -\d+, \d+, '\w+'\))/)[0]
			this.loadVk(script)
			return res.pure_html
		},
		loading() { return this.$store.getters.getPostLoading },
	},
	beforeMount(){
		this.$store.dispatch('uploadPostAsync', this.post_id)
		console.log("beforeMount")
	},
	mounted(){
		console.log("MOUNT")
	},
	updated(){
		console.log("updated")
		// Скрипт VK подгружается в самом начале
		var res = this.$store.getters.getPost
		// Проверяем и устанавливаем тег, что бы дважды не подгружать данные из ВК
		if (this.get_script){
			this.get_script = false
			return null
		}
		// Подгружаем модуль VK
		// https://vk.com/kondorvet?w=wall-146570845_2612
		//<div id="vk_post_-146570845_2612"></div>
		// VK.Widgets.Post("vk_post_-146570845_2612", -146570845, 2612, 'tPTACUmr6SMjyanpf865bmRL');
		// VK.Widgets.Article('my_article', '@thevyshka-perezhivaem-stress-pravilno');

		//console.log('s:', res.pure_html)
		this.get_script = true
		console.log('pure_html:', res.pure_html)
		if (!res.pure_html){ 
			return null 
		}
		var script = ''
		// Проверяем тип записи. Либо это скрипт для поста, либо это ссылка на статью https://vk.com/@vetlight-tremor-u-sobak-prichiny-i-chto-oni-oznachaut
		var check_articles = res.pure_html.match(/(@\S+)/)
		if (check_articles){
			// Это статья
			check_articles = check_articles[0]
			console.log("CHECK:", check_articles)
			script = 'VK.Widgets.Article("vk_article", "@vetlight-tremor-u-sobak-prichiny-i-chto-oni-oznachaut");'
			this.script = '<div id="vk_article"></div>'

		} else {
			// Значит это пост
			// Вырезаем весь скрипт
			script = res.pure_html.replaceAll('\n','').replaceAll('\r','').replaceAll('\t','').match(/<script type="text\/javascript">(.*)<\/script>/)[1]
			// Запускаем обновление ВК
		}
		this.loadVk(script)
		//this.script = res.pure_html

		
	},

}
</script>

<style>
p {
    text-indent: 20px; /* Отступ первой строки в пикселах */
   }
</style>